import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import action from '!!raw-loader!./action.yml';
import { Code } from "gatsby-theme-docz/src/components/Code";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pull-request-changelog-generator"
    }}>{`Pull Request Changelog Generator`}</h1>
    <p>{`A Github Action for generate a changelog, based on the merged pull request labels.`}</p>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`On every `}<inlineCode parentName="p">{`v*`}</inlineCode>{` tag pushed on the `}<strong parentName="p">{`main`}</strong>{` branch:`}</p>
    <ul>
      <li parentName="ul">{`Based on the merged pull request labels, a new changelog will be created.`}</li>
      <li parentName="ul">{`A new Github release will be created.`}</li>
    </ul>
    <h3 {...{
      "id": "tag-format"
    }}>{`Tag format`}</h3>
    <p>{`The tag must be respect the semver convention:`}</p>
    <ul>
      <li parentName="ul">{`v10.2.31 ✅`}</li>
      <li parentName="ul">{`v1.0.0 ✅`}</li>
      <li parentName="ul">{`v1.0 ❌`}</li>
      <li parentName="ul">{`v1 ❌`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <ul>
      <li parentName="ul">{`Add this to a `}<inlineCode parentName="li">{`.github/workflows/action.yml`}</inlineCode>{` file:`}</li>
    </ul>
    <Code className="yaml" mdxType="Code">
  {action}
    </Code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      